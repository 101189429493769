@font-face {
  font-display:swap;
  font-family:Raleway;
  font-style:normal;
  font-weight:500;
  src:url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCFPrEHJA.woff2) format("woff2");
  unicode-range:U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F 
}
@font-face {
  font-display:swap;
  font-family:Raleway;
  font-style:normal;
  font-weight:500;
  src:url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCMPrEHJA.woff2) format("woff2");
  unicode-range:U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116 
}
@font-face {
  font-display:swap;
  font-family:Raleway;
  font-style:normal;
  font-weight:500;
  src:url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCHPrEHJA.woff2) format("woff2");
  unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB 
}
@font-face {
  font-display:swap;
  font-family:Raleway;
  font-style:normal;
  font-weight:500;
  src:url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCGPrEHJA.woff2) format("woff2");
  unicode-range:U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF 
}
@font-face {
  font-display:swap;
  font-family:Raleway;
  font-style:normal;
  font-weight:500;
  src:url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCIPrE.woff2) format("woff2");
  unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD 
}
* {
  -khtml-user-drag:none!important;
  -moz-user-select:none!important;
  -ms-user-select:none!important;
  -webkit-user-drag:none!important;
  -webkit-user-select:none!important;
  user-select:none!important 
}
div>* {
  cursor:none!important 
}
:root {
  --box-shadow-color:#b0c5ff60;
  --cursor-color:#fff;
  --footer-height:40px;
  --link-color:#619efb;
  --navbar-height:60px;
  --primary-bg-color:#282c34;
  --primary-color:#3b83f6;
  --white:#fff 
}
html {
  --cursor-color:#000;
  --image-gradient:linear-gradient(to bottom left,#111010ad,#0c0a16dc);
  --imp-text-color:#70c1f0;
  --section-background-color:linear-gradient(to bottom left,#11101094,#0c0818e7) 
}
html.dark-mode {
  --cursor-color:#fff 
}
body {
  background-image:linear-gradient(to left,#1b1429,#140f23,#0f0c17,#0c0910,#000);
  font-family:Raleway,Arial,sans-serif!important;
  margin:0 
}
#no-scroll {
  height:100vh;
  overflow:hidden 
}
@keyframes App-logo-spin {
  from {
      transform:rotate(0) 
 }
  to {
      transform:rotate(360deg) 
 }
}
@keyframes wave-animation {
  20% {
      transform:rotate(-8deg) 
 }
  40% {
      transform:rotate(-4deg) 
 }
  50% {
      transform:rotate(10deg) 
 }
  0%,100%,60% {
      transform:rotate(0) 
 }
  10%,30% {
      transform:rotate(14deg) 
 }
}
@keyframes fadeIn {
  0% {
      opacity:0 
 }
  100% {
      opacity:1 
 }
}
@keyframes rotate3d {
  0%,100% {
      transform:perspective(400px) rotateX(0) rotateY(-10deg) rotateZ(0) 
 }
  50% {
      transform:perspective(400px) rotateX(0) rotateY(10deg) rotateZ(0) 
 }
}
@keyframes fadeInShadow {
  0% {
      box-shadow:0 0 10px 0 #3c006100 
 }
  100% {
      box-shadow:0 0 10px 4px #f6e1ff80 
 }
}
@media (prefers-reduced-motion:no-preference) {
  .App-logo {
      animation:20s linear infinite App-logo-spin 
 }
}
@media screen and (max-width:768px) {
  .button-primary,.button-secondary,.close-button {
      box-shadow:none 
 }
  .myAvtar {
      padding-bottom:2em!important;
      padding-top:2em!important 
 }
  .Typewriter__wrapper {
      font-size:1.8em!important;
      font-weight:500!important 
 }
  .nav-link {
      padding:.7rem 1rem!important 
 }
  .navbar {
      background-color:#181a27!important 
 }
  .voice-me-container {
      box-shadow:none!important;
      margin:auto;
      max-width:80vw!important 
 }
  .modal2 {
      max-width:75%!important 
 }
  .buttonsVMDiv {
      align-items:center;
      flex-direction:column 
 }
  .button-primary {
      margin-top:15px 
 }
  .section-title {
      align-items:center;
      display:flex;
      flex-direction:column;
      height:80vh;
      justify-content:center 
 }
  .section {
      display:none 
 }
  .logo2 {
      max-width:20% 
 }
  .justifyNews {
      align-items:center;
      display:flex;
      flex-direction:column;
      justify-content:center 
 }
  .logo-icons {
      font-size:.8em;
      margin:8px 
 }
  .links-container {
      align-items:stretch;
      display:flex;
      flex-direction:column-reverse 
 }
  .image-3d,.navbar-nav .nav-item a::after {
      display:none!important 
 }
}
@media (max-width:767px) {
  .imgsSocial a {
      margin:0!important 
 }
  .about-img {
      padding-top:0!important 
 }
  .axd2 {
      align-items:center;
      display:flex;
      justify-content:center;
      padding-bottom:50px 
 }
  .tech-icons {
      margin:10px 
 }
  .footer-body {
      text-align:center!important 
 }
  .resume-left,.resume-right {
      padding-left:15px!important;
      padding-right:15px!important 
 }
  .cbtn {
      position:relative!important 
 }
}
.cbtn {
  position:absolute 
}
#tsparticles {
  z-index:-1 
}
.particles-fade {
  animation:2s ease-in-out forwards fadeIn 
}
.fade-enter-active {
  opacity:1;
  transition:opacity .5s ease-in 
}
.fade-exit {
  opacity:1 
}
.fade-exit-active {
  opacity:0;
  transition:opacity .5s ease-in 
}
.card-img-top {
  border-radius:5px!important;
  max-height:400px;
  width:auto 
}
.card-image {
  border:1px solid #ccc;
  height:200px;
  object-fit:cover 
}
.card-image2 {
  height:250px!important 
}
.bb {
  border-radius:5px;
  overflow:hidden 
}
.card-image3 {
  max-width:100%;
  object-fit:contain;
  width:60vw 
}
.card-hover-zoom2 {
  width:200px 
}
.card-hover,.card-hover-zoom {
  height:350px;
  width:350px 
}
.card-hover,.card-hover-zoom,.card-hover-zoom2 {
  box-shadow:0 4px 6px #0000001a;
  margin:0 auto 
}
.card-hover-zoom,.card-hover-zoom2 {
  transition:transform .5s 
}
.card-hover-zoom2:hover,.card-hover-zoom:hover {
  transform:scale(1.05) 
}
.Card {
  color:#333 
}
.Card.Title {
  font-size:18px;
  font-weight:700;
  margin:10px 0;
  text-align:center 
}
.Card.Text {
  font-size:14px;
  text-align:center 
}
.Card.Link {
  color:#007bff;
  font-size:14px;
  margin-bottom:10px;
  text-align:center 
}
.Card.Img {
  max-height:200px;
  object-fit:cover 
}
.card-link2 {
  color:inherit;
  font-size:16px;
  text-decoration:none 
}
.buttonsVMDiv {
  display:flex;
  justify-content:space-around 
}
.button-primary,.button-secondary,.close-button {
  border:none;
  border-radius:5px;
  box-shadow:0 4px 6px #587ae748;
  cursor:pointer;
  display:inline-block;
  font-size:1rem;
  overflow:hidden;
  padding:.75rem 1rem;
  text-align:center;
  text-decoration:none;
  transition:transform .5s 
}
.button-primary {
  background-color:var(--primary-color);
  color:var(--white);
  margin-right:1rem 
}
.button-secondary {
  background-color:var(--white);
  color:#2839f1 
}
.close-button {
  background:linear-gradient(#3c83f6ff,#3ccbf699);
  color:var(--white) 
}
.button-4 {
  background:linear-gradient(to bottom,gold,#ffdf00);
  border:1px solid #ffdf00;
  border-radius:5px;
  box-shadow:0 2px 3px #0003;
  color:#683434;
  cursor:pointer;
  font-size:18px;
  font-weight:700;
  margin-top:15px;
  padding:10px 20px;
  text-shadow:0 2px 3px #0003;
  transition:.3s 
}
.button-4:hover {
  background:linear-gradient(to bottom,#ffdf00,gold);
  border:1px solid gold;
  box-shadow:0 2px 6px #0006 
}
.donation-button {
  border-radius:5px;
  cursor:pointer;
  margin-top:15px;
  padding:10px 20px;
  text-shadow:0 2px 3px #0003;
  transition:.15s 
}
.button-4:hover,.button-primary:hover,.button-secondary:hover,.close-button:hover,.donation-button:hover {
  animation-duration:.5s;
  transform:scale(1.1) 
}
.button-content {
  align-items:center;
  display:flex;
  gap:.5rem;
  justify-content:center 
}
.verifiedBadge {
  display:flex!important;
  gap:.5rem;
  height:20px;
  justify-content:center!important;
  margin-bottom:5px 
}
.button-text {
  margin-left:.5rem 
}
.social-button {
  align-items:center;
  border-radius:25%;
  box-shadow:0 4px 8px var(--box-shadow-color);
  display:flex;
  flex-direction:column;
  justify-content:center;
  overflow:hidden;
  position:relative;
  transition:transform .2s cubic-bezier(0,.2,.5,3) 
}
.social-button img {
  height:100%;
  object-fit:cover;
  width:100% 
}
.social-button:hover {
  transform:scale(1.1) 
}
button:focus {
  box-shadow:none!important 
}
#preloader {
  background-color:#0c0513;
  background-image:url(./Assets/pre.svg);
  background-position:center;
  background-repeat:no-repeat;
  height:100%;
  left:0;
  position:fixed;
  top:0;
  width:100%;
  z-index:99999 
}
::-webkit-scrollbar {
  width:7px 
}
::-webkit-scrollbar-track {
  background:#2d1950 
}
::-webkit-scrollbar-thumb {
  background:#abb1e9;
  border-radius:12px 
}
::-webkit-scrollbar-thumb:hover {
  background:#7099f0;
  border-radius:#4569ac 
}
.modal-open {
  display:block 
}
.modal-backdrop {
  z-index:994!important 
}
.modal-background {
  align-items:center;
  backdrop-filter:blur(10px);
  background:linear-gradient(#000000b3,#32245380);
  display:flex;
  height:100vh;
  justify-content:center;
  left:0;
  position:fixed;
  top:0;
  width:100vw;
  z-index:10 
}
.modal2 {
  animation:.5s fadeIn;
  background:linear-gradient(#ffff,#5b429bd9);
  border-radius:10px;
  max-width:750px;
  padding:20px;
  text-align:center;
  z-index:11 
}
.custom-modal {
  z-index:998!important 
}
.navbar {
  transition:.3s ease-out!important 
}
#preloader-none,.fade-enter {
  opacity:0 
}
.modal-closed,.preloader-hidden {
  display:none 
}
@media screen and (max-width:2000px) {
  .navbar {
      font-size:1.2rem!important 
 }
}
@media screen and (max-width:1400px) {
  .navbar {
      font-size:1rem!important 
 }
}
@media screen and (max-width:1200px) {
  .navbar {
      font-size:.8rem!important 
 }
}
@media screen and (max-width:992px) {
  .navbar {
      font-size:.45rem!important 
 }
}
@media screen and (max-width:768px) {
  .navbar {
      font-size:1rem!important 
 }
}
.navbar-toggler {
  background-color:transparent!important;
  border-color:transparent!important;
  position:relative!important 
}
.marg {
  margin-left:auto 
}
.navbar-toggler span {
  background-color:#70c1f0!important;
  display:block!important;
  height:4px!important;
  left:0!important;
  margin-bottom:5px!important;
  margin-top:5px!important;
  opacity:1!important;
  transform:rotate(0)!important;
  width:27px!important 
}
.navbar-toggler:active,.navbar-toggler:focus {
  outline:0!important 
}
.navbar-toggler span:first-child,.navbar-toggler span:nth-child(3) {
  transition:transform .35s ease-in-out!important 
}
.navbar-toggler:not(.collapsed) span:first-child {
  left:12px!important;
  opacity:.9!important;
  position:absolute!important;
  top:10px!important;
  transform:rotate(135deg)!important 
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  background-color:transparent!important;
  height:12px!important;
  visibility:hidden!important 
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  left:12px!important;
  opacity:.9!important;
  position:absolute!important;
  top:10px!important;
  transform:rotate(-135deg)!important 
}
.navbar-brand {
  color:#fafafa!important 
}
.logo {
  height:1.4em!important;
  width:2.5em!important 
}
.navbar-nav .nav-link {
  color:#fff;
  padding-left:1rem!important;
  padding-right:1rem!important 
}
.navbar-nav:hover .nav-link:hover {
  color:#fefcffbf!important 
}
.nav-link {
  padding:.8rem 1rem!important 
}
.navbar-nav .nav-item {
  margin-left:20px;
  position:relative 
}
.navbar-nav .nav-item a {
  font-weight:400;
  position:relative;
  transition:.3s ease-out;
  z-index:1 
}
.navbar-nav .nav-item a::after {
  background:#70c1f0;
  border-radius:16px;
  bottom:1px;
  content:"";
  display:block;
  height:3px;
  left:0;
  position:relative;
  transition:.3s ease-out;
  width:0;
  z-index:-1 
}
.navbar-nav .nav-item a:hover::after {
  width:100% 
}
.desktop-section {
  align-items:center;
  display:grid;
  display:flex;
  gap:5rem;
  grid-template-columns:repeat(2,1fr);
  justify-content:center 
}
.section-title {
  align-items:center;
  margin-bottom:3rem 
}
.section {
  align-items:center;
  display:flex;
  margin-left:auto;
  margin-right:auto;
  max-width:100vw;
  width:80vw 
}
.section h2 {
  font-size:2.5rem 
}
.section p {
  font-size:1rem;
  line-height:1.6;
  max-width:30vw 
}
.about-section {
  color:#fff!important;
  height:auto;
  padding-bottom:30px!important;
  padding-top:70px!important;
  position:relative!important 
}
.project-section {
  padding-bottom:30px!important;
  padding-top:100px!important;
  position:relative!important 
}
.home-about-section {
  padding-bottom:70px!important;
  padding-top:60px!important;
  position:relative 
}
.home-section {
  align-items:center;
  display:flex;
  justify-content:center;
  overflow:hidden;
  position:relative!important;
  z-index:995 
}
.home-section,.home-section2 {
  background-color:#0b003b;
  background-image:var(--image-gradient),url(./Assets/home-bg.webp);
  background-position:top center;
  background-repeat:no-repeat;
  background-size:cover;
  height:100vh;
  padding-bottom:30px!important;
  padding-top:70px!important 
}
.chat-section {
  align-items:center;
  background-color:#0b003b;
  background-image:var(--image-gradient),url(./Assets/home-bg.webp);
  background-size:cover;
  color:#fff;
  display:flex;
  flex-direction:column-reverse;
  height:88vh;
  justify-content:flex-start;
  overflow:hidden;
  position:relative;
  z-index:995 
}
.links-container {
  display:flex;
  justify-content:space-around 
}
.page-container {
  align-items:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  min-height:100vh;
  padding-bottom:30px!important;
  padding-top:70px!important;
  width:100% 
}
.container-fluid {
  overflow:hidden 
}
.container {
  scrollbar-gutter:stable both-edges!important 
}
.social-container {
  display:flex;
  flex-direction:row;
  gap:1rem;
  justify-content:center;
  padding-bottom:1rem;
  padding-top:1rem 
}
.card-container {
  height:100%;
  padding:20px 
}
.card-container2 {
  height:100% 
}
.card-container3 {
  height:100%;
  padding:5px!important 
}
.card-title2 {
  margin:0!important 
}
.card-container,.card-container2,.card-container3 {
  align-items:center;
  display:flex;
  flex-direction:column;
  justify-content:center 
}
.voice-me-container {
  background:linear-gradient(175deg,#ffff,#5b429bd9);
  border-radius:15px;
  box-shadow:0 10px 50px #c8a0ff8c;
  margin-top:50px;
  max-width:400px;
  padding:20px;
  position:relative 
}
.main-container {
  display:flex;
  flex-direction:column;
  min-height:100vh 
}
.App {
  text-align:center 
}
.App-logo {
  height:40vmin;
  pointer-events:none 
}
.App-header {
  align-items:center;
  background-color:var(--primary-bg-color);
  color:#fff;
  display:flex;
  flex-direction:column;
  font-size:calc(10px + 2vmin);
  justify-content:center;
  min-height:100vh 
}
.App-link {
  color:var(--link-color) 
}
.main-content {
  flex-grow:1 
}
.blue {
  color:var(--imp-text-color)!important 
}
.sticky {
  backdrop-filter:blur(15px)!important;
  background-color:#1b1a2ea9!important;
  box-shadow:0 10px 10px 0 #09051d2c!important;
  transition:.3s ease-out!important 
}
.wave {
  animation-duration:2.1s;
  animation-iteration-count:infinite;
  animation-name:wave-animation;
  display:inline-block;
  transform-origin:70% 70% 
}
.row {
  align-items:center 
}
.hc2,.home-content {
  color:#f5f5f5;
  max-width:1200px;
  padding:0 20px;
  text-align:left;
  width:100% 
}
.home-contet {
  padding:6rem 0 2rem!important 
}
.main-name {
  color:#70c1f0 
}
.Typewriter__wrapper {
  color:#70c1f0!important;
  font-size:1.9em!important;
  font-weight:600!important 
}
.Typewriter__cursor {
  color:#70c1f0!important;
  font-size:2em!important 
}
.myAvtar {
  justify-content:center!important;
  padding-top:9em!important 
}
.home-about-description {
  color:#fff!important;
  padding-bottom:20px!important;
  padding-top:100px!important;
  text-align:center 
}
.home-about-body {
  font-size:1.2em!important;
  padding-top:50px;
  text-align:left 
}
.home-about-social {
  color:#fff!important;
  padding-top:25px;
  text-align:center!important 
}
.home-about-social-links {
  display:inline-block!important;
  justify-content:center!important;
  padding-inline-start:0!important;
  padding-top:15px!important;
  position:relative!important 
}
.home-social-icons {
  background:#fffffff8!important;
  border-radius:50%!important;
  display:inline-block!important;
  font-size:1.2em!important;
  height:40px!important;
  line-height:2em!important;
  position:relative!important;
  text-align:center!important;
  transition:.5s!important;
  width:40px!important 
}
.home-social-icons::before {
  background:#68187a;
  border-radius:50%;
  content:"";
  height:100%;
  left:0;
  position:absolute;
  top:0;
  transform:scale(.9);
  transition:.5s;
  width:100%;
  z-index:-1 
}
.home-social-icons:hover::before {
  box-shadow:0 0 15px #70c1f0;
  transform:scale(1.1) 
}
.home-social-icons:hover {
  box-shadow:0 0 5px #70c1f0;
  color:#70c1f0;
  text-shadow:0 0 2px #70c1f0 
}
.social-icons {
  display:inline-block!important;
  padding-left:15px;
  padding-right:15px 
}
.footer {
  background-color:#0a0416;
  bottom:0!important;
  padding-bottom:10px!important;
  padding-top:10px!important 
}
.footer-body {
  text-align:center!important;
  z-index:1 
}
.footer h3 {
  color:#fff!important;
  font-size:1em;
  margin-bottom:.5em!important;
  margin-top:.5em!important 
}
.footer-icons {
  margin-bottom:.5em!important;
  margin-top:.5em!important;
  padding:0!important 
}
.project-card {
  height:auto!important;
  padding:20px 25px!important;
  transition:.5s 
}
.project-card-view {
  background-color:#6baee626!important;
  box-shadow:0 1px 2px 2px #7099f0!important;
  color:#fff!important;
  height:100%!important;
  transition:.5s!important 
}
.project-card-view:hover {
  box-shadow:0 0 2px 5px #70c1f0!important;
  overflow:hidden!important;
  border-radius:15px!important 
}
.blog-card {
  height:auto!important;
  padding:50px 25px!important 
}
.blog-card-view {
  background-color:transparent!important;
  box-shadow:0 3px 3px 2px #70c1f0!important;
  color:#fff!important;
  height:100%!important;
  transition:.5s!important 
}
.blog-link {
  color:#fff!important;
  text-decoration:none!important 
}
.blog-link:hover {
  cursor:pointer!important 
}
.blog-card-view:hover {
  box-shadow:0 3px 3px 5px #70c1f0!important;
  overflow:hidden!important;
  transform:scale(1.02)!important 
}
.blog-img {
  border-radius:0!important;
  opacity:.8!important;
  padding:0!important 
}
.btn:focus {
  box-shadow:none!important;
  outline:0!important 
}
.project-heading {
  color:#fff!important;
  font-size:2.3em!important;
  font-weight:500!important;
  padding-top:10px!important 
}
.logo-icons {
  border:1px solid #9179e9;
  border-radius:20px;
  font-size:1em;
  margin:8px;
  padding:10px 12px;
  text-align:center;
  transition:transform .15s,border .15s 
}
.tech-icons {
  border:1.7px solid #70c1f0;
  border-radius:5px;
  box-shadow:4px 5px 4px 3px #5904a823;
  display:table;
  font-size:4.5em;
  margin:15px;
  opacity:.93;
  overflow:hidden;
  padding:10px;
  text-align:center;
  transition:transform .3s,border .3s;
  vertical-align:middle 
}
.logo-icons:hover,.tech-icons:hover {
  transform:scale(1.05) 
}
.tech-icon-images {
  line-height:1.6!important;
  padding:20px!important 
}
.quote-card-view {
  background-color:transparent!important;
  border:none!important;
  color:#fff!important 
}
.about-activity {
  list-style:none!important;
  padding-left:1px!important;
  text-align:left!important 
}
.about-img {
  padding-bottom:50px!important;
  padding-top:50px!important 
}
.image-3d {
  animation:10s cubic-bezier(.4,0,.2,1) infinite rotate3d,4s ease-in forwards fadeInShadow;
  background-color:#f6e1ff;
  border-radius:58px;
  height:200px;
  overflow:hidden;
  width:200px 
}
.voiceit {
  background-color:#0b0f19!important;
  height:100vh;
  position:relative;
  width:100% 
}
.voiceit-iframe {
  border:none;
  bottom:0;
  height:100%;
  left:0;
  margin-bottom:30px!important;
  margin-top:50px!important;
  min-height:100vh;
  padding-bottom:25px;
  right:0;
  top:0;
  width:100%;
  z-index:1!important 
}
.vml {
  height:34.7rem;
  width:61rem;
  overflow:hidden!important 
}
.vml iframe {
  display:flex;
  justify-content:center;
  align-items:center;
  height:100%;
  width:100%;
  overflow:hidden!important 
}
.default-cursor,.default-cursor button {
  cursor:auto!important 
}
.default-cursor a,.default-cursor img,.default-cursor span {
  cursor:pointer!important;
  pointer-events:all!important 
}
.cursor-change {
  cursor:pointer 
}
.card-link,.link-bold {
  color:inherit;
  font-size:20px;
  text-decoration:none 
}
.page-footer {
  margin-top:auto 
}
.col-span-1 {
  max-width:40vw 
}
.overlay-image {
  filter:blur(2px);
  left:20vw;
  max-height:40vw;
  opacity:.5;
  position:absolute;
  top:-7vw 
}
.imgSec {
  height:auto;
  left:-5vw;
  object-fit:contain;
  position:relative 
}
.description-text {
  white-space:pre-wrap;
  word-wrap:break-word 
}
.logo2 {
  max-width:15vh 
}
.heading {
  font-size:2.4em!important;
  padding-left:40px!important 
}
.heading-name {
  font-size:2.5em!important;
  padding-left:40px!important 
}
.active-link {
  color:#000!important;
  text-decoration:underline;
  text-decoration-color:#000 
}
.text-left {
  text-align:left 
}
.highlight {
  color:#a57eff 
}
.highlight2 {
  color:#ca88df 
}
.link-text {
  color:#adc1e6;
  text-decoration:none 
}
.certificate-icon {
  font-size:1.25em 
}
.pdf-document {
  height:500px;
  width:100% 
}
.download-button {
  max-width:250px 
}
.icon-spacer {
  margin-right:8px 
}
.form-section {
  align-items:center;
  display:flex;
  justify-content:space-between;
  margin-bottom:1em 
}
.input-group {
  align-items:center;
  display:flex 
}
.chat-input {
  flex-grow:1;
  margin-right:.5em 
}
.submit-button {
  flex-shrink:0 
}
.chat-list {
  max-height:500px;
  overflow:auto 
}
.section-lm {
  color:#fff;
  margin-top:60px;
  position:absolute;
  top:0;
  z-index:1 
}
.canvaslm {
  align-items:center;
  display:flex;
  flex-direction:column;
  height:100vh;
  justify-content:center;
  margin-top:30px;
  width:100% 
}
.canvaslm video {
  border-radius:15px;
  height:75%;
  object-fit:cover;
  position:absolute;
  width:75% 
}
.canvaslm canvas {
  border-radius:15px;
  box-shadow:0 0 29px 12px #e1bcff66;
  height:75%;
  position:absolute;
  width:75% 
}
.error-message {
  background-color:red;
  bottom:10px;
  color:#fff;
  margin-bottom:10px;
  padding:10px;
  position:absolute;
  text-align:center;
  width:100% 
}
.error-message2 {
  background-color:#e6646448;
  color:#fff;
  padding:10px;
  text-align:center;
  width:100% 
}
.error-image {
  display:block;
  filter:drop-shadow(0px 0px 6px #e66464cc);
  margin:0 auto 
}
.card-text {
  margin-bottom:0 
}
.filters {
  align-items:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center 
}
.filter-item {
  display:flex;
  gap:20px;
  justify-content:center;
  align-items:center 
}
.donateButtons {
  display:flex;
  gap:10px;
  justify-content:center;
  margin:10px 
}
.blockquote-footer,.icon-colour {
  color:#70c1f0!important 
}
.btn-primary,.btn-primary:hover {
  background-color:#70c1f0!important;
  border-color:#70c1f0!important;
  color:#fff!important 
}
@media (max-width:768px) {
  .donateButtons {
      display:block 
 }
  .filter-item {
      flex-direction:column 
 }
}
.noProjects {
  align-content:center;
  align-items:center;
  color:#fff;
  display:flex;
  flex-wrap:wrap;
  height:55vh;
  justify-content:center;
  text-align:center 
}
.imgSocial {
  border:2px solid #fff;
  border-radius:10px;
  height:40px;
  margin:10px 
}
.imgsSocial {
  display:flex;
  flex-wrap:nowrap!important;
  justify-content:center 
}
.imgsSocial a {
  margin:0 140px;
  text-align:center;
  transition:transform .5s 
}
.imgsSocial a:hover {
  transform:scale(1.1) 
}
.copy-text {
  cursor:pointer;
  position:relative 
}
.copy-text:after {
  background:linear-gradient(90deg,#b0e2ff1a,#b0e2ffbf,#f5acff);
  background-position:0;
  border-radius:16px;
  bottom:-2px;
  content:"";
  display:block;
  height:3px;
  left:0;
  position:absolute;
  transition:width .3s ease-out;
  width:0 
}
.copy-text:hover:after {
  left:0;
  width:100% 
}
.downloadSocialCV {
  background-color:#0f8;
  border:2px solid #fff;
  border-radius:25px;
  box-shadow:0 0 10px 1px #afffff80;
  height:40px;
  padding-left:15px;
  padding-right:15px 
}
.button-gradient {
  border:1px solid transparent;
  background-color:#000;
  color:#fff 
}
.button-gradient:before {
  background:linear-gradient(166deg,#ff507a,#7d51df 33%,#2265de 66%,#0bc786) 
}
.form-check-label {
  color:#fff 
}